import React from 'react'
import { Link } from 'gatsby'
import { InternalPresentation } from '../Internal'
import { JORNADA } from '../../constants/routes'
import styles from './HomeContent.module.css'

function HomeContent() {
  return (
    <section className={styles.container}>
      <div className={styles.content}>
        <div className={styles.welcome}>
          <h3>
            Olá, <strong>seja bem-vindo</strong>
            <br /> ao nosso espaço virtual!
          </h3>
          <p>
            Meu nome é Luciana Moura, sou terapeuta a mais de 18 anos, consteladora, terapeuta floral sistêmica e
            educadora certificada em Florais de Bach no The International Training Programme Learning to use
            Healingherbs.
          </p>
          <p>
            Minha <strong>MISSÃO</strong> é inspirar indivíduos e terapeutas a fazerem a jornada evolutiva da Alma
          </p>
          <p>
            Minha <strong>VISÃO</strong> é ser reconhecida como uma profissional de vanguarda na educação e
            desenvolvimento de competências que facilitem a jornada evolutiva da Alma, contribuindo assim, para
            estabelecermos relações mais humanas e verdadeiras.
          </p>
          <p>
            Os <strong>VALORES</strong> que sustentam meu trabalho são: a ética nos relacionamentos, o comprometimento
            com a qualidade e a criação de ações que viabilizem soluções e promovam o crescimento e desenvolvimento
            mútuo.
          </p>
          <Link
            title='Meu currículo profissional'
            to={JORNADA}
            className={`button button--purple ${styles.welcomeButton}`}
          >
            Meu currículo profissional
          </Link>
        </div>
        <div className='aside'>
          <InternalPresentation />
        </div>
      </div>
    </section>
  )
}

export { HomeContent }
