import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Image } from '../UI'
import styles from './HomePartners.module.css'

function HomePartners() {
  const { logo1, logo2, logo3, logo4, logo5, logo6, logo7, logo8 } = useStaticQuery(
    graphql`
      query HomePartners {
        logo1: file(relativePath: { eq: "partners/logo-healingherbs.png" }) {
          childImageSharp {
            fixed(width: 175) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        logo2: file(relativePath: { eq: "partners/logo-bachfoundation.png" }) {
          childImageSharp {
            fixed(width: 89) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        logo3: file(relativePath: { eq: "partners/logo-healingflorais.png" }) {
          childImageSharp {
            fixed(width: 128) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        logo4: file(relativePath: { eq: "partners/logo-blossom.png" }) {
          childImageSharp {
            fixed(width: 129) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        logo5: file(relativePath: { eq: "partners/logo-abrath.png" }) {
          childImageSharp {
            fixed(width: 127) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        logo6: file(relativePath: { eq: "partners/logo-iperoxo.png" }) {
          childImageSharp {
            fixed(width: 114) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        logo7: file(relativePath: { eq: "partners/logo-soulcollage.png" }) {
          childImageSharp {
            fixed(width: 129) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        logo8: file(relativePath: { eq: "partners/logo-constelacao.png" }) {
          childImageSharp {
            fixed(width: 116) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `
  )
  return (
    <section className={styles.container}>
      <div className={styles.content}>
        <div className={styles.intro}>
          <h3>Parceiros e Certificações</h3>
          <h4>
            Nossos <strong>cursos são certificados</strong> e <strong>inspirados</strong> pela trajetória pessoal e
            profissional de grandes Almas.
          </h4>
        </div>
        <ul className={styles.listLogos}>
          <li>
            <Image className={styles.partnerLogo} fixed={logo1.childImageSharp.fixed} />
          </li>
          <li>
            <Image className={styles.partnerLogo} fixed={logo2.childImageSharp.fixed} />
          </li>
          <li>
            <Image className={styles.partnerLogo} fixed={logo3.childImageSharp.fixed} />
          </li>
          <li>
            <Image className={styles.partnerLogo} fixed={logo4.childImageSharp.fixed} />
          </li>
          <li>
            <Image className={styles.partnerLogo} fixed={logo5.childImageSharp.fixed} />
          </li>
          <li>
            <Image className={styles.partnerLogo} fixed={logo6.childImageSharp.fixed} />
          </li>
          <li>
            <Image className={styles.partnerLogo} fixed={logo7.childImageSharp.fixed} />
          </li>
          <li>
            <Image className={styles.partnerLogo} fixed={logo8.childImageSharp.fixed} />
          </li>
        </ul>
      </div>
    </section>
  )
}

export { HomePartners }
