import React from 'react'

import { SEO } from '../components/UI'
import {
  HomeBanner,
  HomeContent,
  HomeAbout,
  HomeTraining,
  HomeCounters,
  HomeTestimonials,
  HomeCourses,
  HomePartners
} from '../components/Home'
export default function Page() {
  return (
    <>
      <SEO title='Home' />
      <HomeBanner />
      <HomeContent />
      <HomeAbout />
      <HomeTraining />
      <HomeCounters />
      <HomeTestimonials />
      <HomeCourses />
      <HomePartners />
    </>
  )
}
