import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { Image, Slideshow } from '../UI'
import { JORNADA } from '../../constants/routes'
import styles from './HomeAbout.module.css'

function HomeAbout() {
  const { shareContent } = useStaticQuery(
    graphql`
      query HomeAbout {
        shareContent: file(relativePath: { eq: "home/about.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  return (
    <section className={styles.container}>
      <div className={styles.content}>
        <div className={styles.intro}>
          <h3>Sobre a Lu Moura</h3>
          <h4>
            Conheça alguns de <strong>nossos diferenciais</strong> compartilhado pelos nossos alunos!
          </h4>
        </div>
        <div className={styles.share}>
          <div className={styles.shareContent}>
            <div className={styles.shareContentItem}>
              <div className={styles.shareContentIcon}></div>
              <div className={styles.shareContentDescription}>
                <h3>
                  Cursos Presenciais
                  <br />e à distância <span className={styles.shareContentNews}>novidade</span>
                </h3>
                <p>
                  Estamos disponibilizando alguns cursos em ambos os formatos (online ou presencial) com a mesma
                  qualidade, foco e carinho que você já conhece.
                </p>
              </div>
            </div>

            <div className={styles.shareContentItem}>
              <div className={styles.shareContentIcon}></div>
              <div className={styles.shareContentDescription}>
                <h3>
                  Aulas Práticas e
                  <br />
                  Vivenciais
                </h3>
                <p>
                  Todos os nossos cursos, formações e workshops, têm o equilíbrio perfeito entre teoria e prática com
                  foco em mercado de atuação.
                </p>
              </div>
            </div>
          </div>
          <div className={styles.shareImage}>
            <Slideshow className={styles.shareContentSlide} />
            <Image className={styles.shareContentImage} fluid={shareContent.childImageSharp.fluid} />
          </div>
          <div className={styles.shareContent}>
            <div className={styles.shareContentItem}>
              <div className={styles.shareContentIcon}></div>
              <div className={styles.shareContentDescription}>
                <h3>
                  Contato
                  <br />
                  “olho no olho”
                </h3>
                <p>
                  Possibilidade de tirar as suas dúvidas e interagir com os alunos e a Luciana pelos encontros
                  presenciais ou online, via videoconferência.
                </p>
              </div>
            </div>

            <div className={styles.shareContentItem}>
              <div className={styles.shareContentIcon}></div>
              <div className={styles.shareContentDescription}>
                <h3>
                  Todos os cursos são
                  <br />
                  únicos e incríveis
                </h3>
                <p>
                  Um dos maiores diferenciais é que o conteúdo dos cursos se moldam com base na necessidade da turma ou
                  pelas novidades de mercado.
                </p>
              </div>
            </div>
          </div>
        </div>
        <nav className={styles.shareNav}>
          <Link title='Mais sobre a Lu Moura' to={JORNADA} className='button button--purple'>
            Mais sobre a Lu Moura
          </Link>
        </nav>
      </div>
    </section>
  )
}

export { HomeAbout }
