import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { Image } from '../UI'
import styles from './HomeBanner.module.css'
import Background from '../../images/home/banner_shape_background.svg'
import BackgroundMobile from '../../images/home/banner_shape_background_mobile.svg'
import Corner from '../../images/home/banner_shape_corner.svg'
import { SYSTEMICTERAPY } from '../../constants/routes'

function HomeBanner() {
  const { flowers, flowersMobile, bigFlower, flasks, flowersB } = useStaticQuery(
    graphql`
      query HomeBanner {
        flowers: file(relativePath: { eq: "home/banner_flores.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        flowersMobile: file(relativePath: { eq: "home/banner_flores_mobile.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        flowersB: file(relativePath: { eq: "home/banner_floresB.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        bigFlower: file(relativePath: { eq: "home/banner_flor_brilho.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        flasks: file(relativePath: { eq: "home/banner_frascos_florais.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )
  return (
    <section className={styles.container}>
      <div className={styles.content}>
        <div className={styles.socialBox}>
          <ul>
            <li>
              <a
                href='https://www.instagram.com/luciana_smoura/'
                title='Instagram'
                target='_blank'
                rel='noopener noreferrer'
              >
                
              </a>
            </li>
            <li>
              <a
                href='http://www.facebook.com/UseFloraisTerapiasAlternativas?ref=ts&fref=ts'
                target='_blank'
                rel='noopener noreferrer'
                title='Facebook'
              >
                
              </a>
            </li>
          </ul>
        </div>
        <div className={styles.textBox}>
          <h2>Formação Profissionalizante</h2>
          <h3>
            Terapia Floral <br />
            <strong>Sistêmica</strong>
          </h3>
          <small>Agora também 100% Online!</small>
          <Link title='Saiba mais' to={SYSTEMICTERAPY} className={`button button--white ${styles.bannerButton}`}>
            Saiba mais
          </Link>
        </div>
      </div>
      <div className={styles.background}>
        <div className={styles.backgroundCenter}>
          <Image className={styles.flasks} fluid={flasks.childImageSharp.fluid} style={{ position: 'absolute' }} />
          <Image className={styles.flowersB} fluid={flowersB.childImageSharp.fluid} style={{ position: 'absolute' }} />
        </div>
        <Image className={styles.flowers} fluid={flowers.childImageSharp.fluid} style={{ position: 'absolute' }} />
        <Image
          className={styles.flowersMobile}
          fluid={flowersMobile.childImageSharp.fluid}
          style={{ position: 'absolute' }}
        />
        <Image className={styles.bigFlower} fluid={bigFlower.childImageSharp.fluid} style={{ position: 'absolute' }} />
        <div className={styles.shapeWrapper}>
          <Background className={styles.shape} />
          <BackgroundMobile className={styles.shapeMobile} />
          <Corner className={styles.corner} />
        </div>
      </div>
    </section>
  )
}

export { HomeBanner }
