import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { Image } from '../UI'
import {
  FLORAIS_ANIMAIS,
  INTRO_SOULCOLLAGE,
  LEITURA_CAMPO_ENERGETICO,
  FLORAIS_OLEOS_ESSENCIAIS
} from '../../constants/routes'
import styles from './HomeCourses.module.css'

function HomeCourses() {
  const { course01, course02, course03, course04 } = useStaticQuery(
    graphql`
      query HomeCourses {
        course01: file(relativePath: { eq: "courses/tumb-cursocomplementar-oleos.png" }) {
          childImageSharp {
            fluid(maxWidth: 256) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        course02: file(relativePath: { eq: "courses/tumb-cursocomplementar-floraisparaanimais.png" }) {
          childImageSharp {
            fluid(maxWidth: 256) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        course03: file(relativePath: { eq: "courses/tumb-cursocomplementar-leituracampo.png" }) {
          childImageSharp {
            fluid(maxWidth: 256) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        course04: file(relativePath: { eq: "courses/tumb-cursocomplementar-soulcollage.png" }) {
          childImageSharp {
            fluid(maxWidth: 256) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )
  return (
    <section className={styles.container}>
      <div className={styles.content}>
        <div className={styles.intro}>
          <h2>Online ou Presencial</h2>
          <h3>
            Nossos Cursos <strong>Complementares</strong>
          </h3>
          <ul className={styles.introInfos}>
            <li>
              <i></i>até 20 horas aula
            </li>
            <li>
              <i></i>Aulas práticas
            </li>
          </ul>
          <p>
            Buscar o desenvolvimento profissional é fundamental na jornada evolutiva da Alma. Por isso, preparei alguns
            cursos específicos para complementar e expandir tua área de atuação profissional.
            <br />O melhor de tudo é que os cursos são online, você pode fazer de onde estiver e no conforto da sua
            casa.
          </p>
        </div>
        <div className={styles.courses}>
          <div className={styles.boxWrapper}>
            <div className={styles.box}>
              <Link title='Florais de Bach e Óleos Essenciais' to={FLORAIS_OLEOS_ESSENCIAIS} className={styles.boxLink}>
                <figure className={styles.boxThumb}>
                  <Image fluid={course01.childImageSharp.fluid} alt='Florais de Bach e Óleos Essenciais' />
                </figure>
                <h4>Florais de Bach e Óleos Essenciais</h4>
                <p>Trabalhe com as sinergias entre Florais de Bach e Óleos Essenciais</p>
              </Link>
            </div>
          </div>
          <div className={styles.boxWrapper}>
            <div className={styles.box}>
              <Link title='Florais de Bach para Animais' to={FLORAIS_ANIMAIS} className={styles.boxLink}>
                <figure className={styles.boxThumb}>
                  <Image fluid={course02.childImageSharp.fluid} alt='Florais de Bach para Animais' />
                </figure>
                <h4>Florais de Bach para Animais</h4>
                <p>Cura dos estados emocionais dos animais com Florais de Bach</p>
              </Link>
            </div>
          </div>
          <div className={styles.boxWrapper}>
            <div className={styles.box}>
              <Link
                title='Leitura do Campo Energético Emocional'
                to={LEITURA_CAMPO_ENERGETICO}
                className={styles.boxLink}
              >
                <figure className={styles.boxThumb}>
                  <Image fluid={course03.childImageSharp.fluid} alt='Leitura do Campo Energético Emocional' />
                </figure>
                <h4>Leitura do Campo Energético Emocional</h4>
                <p>Mapeamento emocional e limpeza e harmonização dos chacras </p>
              </Link>
            </div>
          </div>
          <div className={styles.boxWrapper}>
            <div className={styles.box}>
              <Link title='Curso Introdutório de SoulCollage®' to={INTRO_SOULCOLLAGE} className={styles.boxLink}>
                <figure className={styles.boxThumb}>
                  <Image fluid={course04.childImageSharp.fluid} alt='Curso Introdutório de SoulCollage® ' />
                </figure>
                <h4>Curso Introdutório de SoulCollage® </h4>
                <p>Um olhar mais profundo e íntimo para você e seus clientes</p>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export { HomeCourses }
