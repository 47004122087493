import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Image, Carousel } from '../UI'
import styles from './HomeTestimonials.module.css'

function HomeTestimonials() {
  const { testimonials, testimonialsMobile } = useStaticQuery(
    graphql`
      query HomeTestimonials {
        testimonials: file(relativePath: { eq: "home/testimonials-background.png" }) {
          childImageSharp {
            fluid(maxWidth: 1196) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        testimonialsMobile: file(relativePath: { eq: "home/testimonials-background-mobile.png" }) {
          childImageSharp {
            fluid(maxWidth: 375) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  return (
    <section className={styles.container}>
      <div className={styles.content}>
        <Image
          className={`${styles.background} ${styles.backgroundDesktop}`}
          fluid={testimonials.childImageSharp.fluid}
        />
        <Image
          className={`${styles.background} ${styles.backgroundMobile}`}
          fluid={testimonialsMobile.childImageSharp.fluid}
        />
        <div className={styles.carouselContainer}>
          <div className={styles.carouselWrapper}>
            <Carousel isPurple />
          </div>
        </div>
      </div>
    </section>
  )
}

export { HomeTestimonials }
