import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { Image } from '../UI'
import { HOWTOUSE } from '../../constants/routes'
import styles from './HomeTraining.module.css'

function HomeTraining() {
  const { mosaic, mosaicMobile } = useStaticQuery(
    graphql`
      query HomeTraining {
        mosaic: file(relativePath: { eq: "home/training.png" }) {
          childImageSharp {
            fluid(maxWidth: 621) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        mosaicMobile: file(relativePath: { eq: "home/training_mobile.png" }) {
          childImageSharp {
            fluid(maxWidth: 313) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )
  return (
    <section className={styles.container}>
      <div className={styles.content}>
        <div className={styles.trainingImage}>
          <Image className={styles.mosaicDesktop} fluid={mosaic.childImageSharp.fluid} />
          <Image className={styles.mosaicMobile} fluid={mosaicMobile.childImageSharp.fluid} />
        </div>
        <div className={styles.intro}>
          <h2>Somente Presencial</h2>
          <h3>
            Aprenda a usar
            <br /> <strong>Florais de Bach</strong>
          </h3>
          <ul className={styles.introInfos}>
            <li>
              <i></i>60 horas aula
            </li>
            <li>
              <i></i>Certificado Internacional HealingHerbs
            </li>
          </ul>
          <p>
            Nossa formação em parceria com a HealingHerbs é o início de uma grande jornada junto a cura e o entendimento
            dos estados emocionais. Com uma abordagem inovadora, a formação é completa e indicada a todos que pretendem
            conhecer os benefícios dos Florais de Bach.
          </p>
          <nav className={styles.trainingNav}>
            <Link title='Tudo sobre a formação' to={HOWTOUSE} className='button button--purple'>
              Tudo sobre a formação
            </Link>
          </nav>
        </div>
      </div>
    </section>
  )
}

export { HomeTraining }
