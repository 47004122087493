import React, { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import styles from './HomeCounters.module.css'
import PlusIcon from '../../images/home/plus.svg'

function HomeCounters() {
  const [ref, inView] = useInView()
  const [box1, setBox1] = useState(0)
  const [box2, setBox2] = useState(0)
  const [box3, setBox3] = useState(0)

  useEffect(() => {
    if (inView) {
      box1 < 500 && setTimeout(() => setBox1(box1 + 4), 30)
      box2 < 2000 && setTimeout(() => setBox2(box2 + 8), 30)
      box3 < 400 && setTimeout(() => setBox3(box3 + 2), 30)
      return
    }
  }, [inView, box1, box2, box3])

  return (
    <section className={styles.container}>
      <div className={styles.content} ref={ref}>
        <div className={styles.counter}>
          <div className={styles.number}>
            <span>{box1}</span>
            <PlusIcon className={styles.plus} />
          </div>
          <h2 className={styles.description}>Cursos Ministrados</h2>
        </div>
        <div className={styles.counter}>
          <div className={styles.number}>
            <span>{box2}</span>
            <PlusIcon className={styles.plus} />
          </div>
          <h2 className={styles.description}>Alunos Formados</h2>
        </div>
        <div className={styles.counter}>
          <div className={styles.number}>
            <span>{box3}</span>
            <PlusIcon className={styles.plus} />
          </div>
          <h2 className={styles.description}>Atendimentos</h2>
        </div>
      </div>
    </section>
  )
}

export { HomeCounters }
